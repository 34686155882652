import { TOAST_ID } from 'src/constants/APIConstants';
import {
    API_USER_INFORMATION_URL,
    API_USER_INFORMATION_URL_PLACEHOLDER,
    ATTRIBUTE_BINDING,
    ATTRIBUTE_ENTITY,
    ATTRIBUTE_LOCATION,
    AUTHORIZATION_BASE_URL,
    AUTHORIZATION_BASE_URL_PLACEHOLDER,
    CLIENT_EMAIL_SCOPE,
    CLIENT_EMAIL_SCOPE_PLACEHOLDER,
    CLIENT_ID,
    CLIENT_ID_PLACEHOLDER,
    CLIENT_SECRET,
    CLIENT_SECRET_PLACEHOLDER,
    HTTP_POST_LABEL,
    JWT_SET_URL,
    JWT_SET_URL_PLACEHOLDER,
    METADATA_FILE,
    METADATA_URL,
    QUERY_SELECTOR_CERTIFICATE,
    QUERY_SELECTOR_ENTITYDESCRIPTOR,
    QUERY_SELECTOR_SINGLESIGNONSERVICE,
    TOKEN_URL,
    TOKEN_URL_PLACEHOLDER
} from 'src/constants/LabelText';
import { showToast, TOAST_TYPE } from 'src/helpers/utility';
import { axiosInstance } from 'src/services/axiosInstance';

import {
    AuthMethod,
    IDPDetails,
    OIDCConfiguration,
    OIDCKEY
} from './SamlSettings.types';

export const SSO_NOT_CONFIGURED =
    'SSO is not configured. Please set it up and try again.';
export const INVALID_SSO = 'The configured SSO is invalid.';
export const SSO_CONFIGURED = 'SSO has been configured successfully.';
export const SAVE_BEFORE_TEST =
    'Please save the data before testing the connection.';
export const SAML_SWITCH_ERROR =
    'OIDC is already configured. Please set up SAML to test the connection.';
export const CONFIGURATION_SAVED = 'SSO configuration saved successfully';

export const metadataDropDownValues = [METADATA_FILE, METADATA_URL];
export const authMethodDropDownValues = [AuthMethod.SAML, AuthMethod.OIDC];
export const SAML_SP_FIELDS = ['singleSignOnUrl', 'entityId'];
export const OIDC_SP_FIELDS = ['authorizationEndpoint'] as const;

export const initialOIDCFieldsData: OIDCConfiguration = {
    clientId: '',
    clientSecret: '',
    emailScope: '',
    authorizationBaseUri: '',
    tokenUri: '',
    userInfoUri: '',
    jwkSetUri: ''
};

export const validLengthConfiguration: Record<OIDCKEY, number> = {
    clientId: 500,
    clientSecret: 500,
    emailScope: 50,
    authorizationBaseUri: 2048,
    tokenUri: 2048,
    userInfoUri: 2048,
    jwkSetUri: 2048
};

export const isValidUrl = (url: string) => {
    try {
        return Boolean(new URL(url));
    } catch {
        return false;
    }
};

const URLS_FIELDS_KEYS = [
    'authorizationBaseUri',
    'tokenUri',
    'userInfoUri',
    'jwkSetUri'
];

export const validate = (key: string, value: string) => {
    if (value.trim().length === 0) {
        return 'This field is required';
    }
    if (URLS_FIELDS_KEYS.includes(key) && !isValidUrl(value)) {
        return 'URL is invalid';
    }

    const maxLimit = validLengthConfiguration[key as OIDCKEY];
    if (value.length > maxLimit) {
        return `Max ${maxLimit} characters are allowed.`;
    }
    return '';
};

export const oidcInputs = [
    {
        key: 'clientId',
        label: CLIENT_ID,
        placeholder: CLIENT_ID_PLACEHOLDER
    },
    {
        key: 'clientSecret',
        label: CLIENT_SECRET,
        placeholder: CLIENT_SECRET_PLACEHOLDER
    },
    {
        key: 'emailScope',
        label: CLIENT_EMAIL_SCOPE,
        placeholder: CLIENT_EMAIL_SCOPE_PLACEHOLDER
    },
    {
        key: 'authorizationBaseUri',
        label: AUTHORIZATION_BASE_URL,
        placeholder: AUTHORIZATION_BASE_URL_PLACEHOLDER
    },
    {
        key: 'tokenUri',
        label: TOKEN_URL,
        placeholder: TOKEN_URL_PLACEHOLDER
    },
    {
        key: 'userInfoUri',
        label: API_USER_INFORMATION_URL,
        placeholder: API_USER_INFORMATION_URL_PLACEHOLDER
    },
    {
        key: 'jwkSetUri',
        label: JWT_SET_URL,
        placeholder: JWT_SET_URL_PLACEHOLDER
    }
];

export const parseXMLData = (xmlFileData: string) => {
    const parser = new DOMParser();
    const xml = parser.parseFromString(xmlFileData, 'text/xml');
    const certificateValue = xml.querySelector(QUERY_SELECTOR_CERTIFICATE)
        ?.childNodes[0]?.nodeValue;
    const entityID = xml
        .querySelector(QUERY_SELECTOR_ENTITYDESCRIPTOR)
        ?.getAttribute(ATTRIBUTE_ENTITY);
    const singleSignOnElements = xml.querySelectorAll(
        QUERY_SELECTOR_SINGLESIGNONSERVICE
    );
    const signOnUrlElement = Array.from(singleSignOnElements).find(element =>
        element.getAttribute(ATTRIBUTE_BINDING)?.includes(HTTP_POST_LABEL)
    );
    const signOnUrl = signOnUrlElement?.getAttribute(ATTRIBUTE_LOCATION);

    return {
        url: signOnUrl,
        entityid: entityID,
        certificate: certificateValue
    } as IDPDetails;
};

export const handleSSOPopup = (data: string) => {
    const { origin } = window.location;
    const popup = window.open(
        `${axiosInstance.defaults.baseURL}${data}`,
        'okta',
        'width=600,height=600'
    );

    // Close the popup when it is opened for 5 mins
    const timeout = setTimeout(() => {
        if (popup && popup.closed === false) {
            popup.close();
            clearTimeout(timeout);
        }
    }, 3_00_000);

    const intervalId = setInterval(() => {
        if (popup && popup.closed === false) {
            try {
                const { href = '', search = '' } = popup.location;

                if (href.includes('/login?error=')) {
                    popup.close();
                    const urlParams = new URLSearchParams(search);
                    const error = urlParams.get('error');

                    showToast(error || INVALID_SSO, TOAST_TYPE.ERROR, TOAST_ID);
                    clearInterval(intervalId);
                    clearTimeout(timeout);
                }
                if (href === `${origin}/`) {
                    popup.close();
                    showToast(SSO_CONFIGURED, TOAST_TYPE.INFO, TOAST_ID);
                    clearInterval(intervalId);
                    clearTimeout(timeout);
                }
            } catch (error) {
                // In case of cross-origin restrictions, ignore and continue
            }
        }
    }, 500);
};
