export interface SamlSettingsProps {
    setIsLoading: (
        isComponentLoading: boolean,
        needFullPageLoading?: boolean
    ) => undefined;
}

export interface SAMLSPDetails {
    singleSignOnUrl: string;
    entityId: string;
    isactive: boolean;
}
export interface OIDCSPDetails {
    authorizationEndpoint: string;
}

export interface IDPDetails {
    url: string;
    entityid: string;
    certificate: string;
}

export interface OIDCConfiguration {
    clientId: string;
    clientSecret: string;
    emailScope: string;
    authorizationBaseUri: string;
    tokenUri: string;
    userInfoUri: string;
    jwkSetUri: string;
}

export type OIDCKEY = keyof OIDCConfiguration;

export enum AuthMethod {
    SAML = 'SAML',
    OIDC = 'OpenID Connect'
}
