import { FC } from 'react';

import { constants, TextBox } from '@armis/armis-ui-library';
import { InputAdornment, useTheme } from '@mui/material';
import { IoIosClose } from 'react-icons/io';

type Props = {
    label: string;
    value: string;
    error: string;
    placeholder: string;
    handleChange: (value: string) => void;
};

const OIDCInput: FC<Props> = ({
    label,
    placeholder,
    value,
    error,
    handleChange
}) => {
    const theme = useTheme();

    return (
        <>
            <span>{label}</span>
            <TextBox
                autoComplete="off"
                className="url"
                error={error.length > 0}
                fullWidth
                helperText={error}
                hiddenLabel
                InputProps={{
                    endAdornment: value && (
                        <InputAdornment
                            onClick={() => {
                                handleChange('');
                            }}
                            position="end"
                            variant="outlined"
                        >
                            <IoIosClose />
                        </InputAdornment>
                    ),
                    style: {
                        backgroundColor:
                            theme.palette.mode === 'dark'
                                ? constants.COLOR_GENERAL_29
                                : constants.COLOR_WHITE,
                        fontSize: '13px !important'
                    }
                }}
                onChange={e => {
                    handleChange(e.target.value);
                }}
                placeholder={placeholder}
                size="small"
                value={value}
                variant="outlined"
            />
        </>
    );
};

export default OIDCInput;
