import { useCallback, useEffect, useState } from 'react';

import { Button, Select, TextBox, Upload } from '@armis/armis-ui-library';
import { InputAdornment, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { isEqual } from 'lodash';
import { IoIosClose } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { TOAST_ID } from 'src/constants/APIConstants';
import { SAML_SP_MAPPING } from 'src/constants/APIResponse';
import { Privileges, Resources } from 'src/constants/CommonConstants';
import {
    CHOOSE_METADATA_FORMAT,
    LOGIN_MANAGEMENT,
    METADATA_ERROR,
    METADATA_FILE,
    METADATA_URL,
    METADATA_URL_ERROR,
    SAML_IDP_DESCRIPTION,
    SAML_IDP_DETAILS,
    SAML_SP_DESCRIPTION,
    SAML_SP_DETAILS,
    SAVE,
    SSO_LOGIN_METHOD,
    UPLOAD_FILE
} from 'src/constants/LabelText';
import {
    displayErrorMessage,
    showToast,
    TOAST_TYPE
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { Header } from 'src/pages/components/Header';
import { TextToClipBoard } from 'src/pages/components/TextToClipBoard/TextToClipBoard';
import {
    ChooseMethodBlock,
    StyledActionsBox,
    StyledButton,
    StyledInput
} from 'src/pages/containers/SSO&SAML/SamlSettings.style';
import {
    AuthMethod,
    IDPDetails,
    OIDCKEY,
    OIDCSPDetails,
    SamlSettingsProps,
    SAMLSPDetails
} from 'src/pages/containers/SSO&SAML/SamlSettings.types';
import {
    getSPDetails,
    getSSOId,
    getURLDetails,
    postIDPDetails,
    postOIDCDetails
} from 'src/services/api.service';
import { selectUser } from 'src/store/slices/userSlice';

import OIDCSettings from './OIDCSettings';
import {
    authMethodDropDownValues,
    CONFIGURATION_SAVED,
    handleSSOPopup,
    initialOIDCFieldsData,
    metadataDropDownValues,
    parseXMLData,
    SAML_SP_FIELDS,
    SAML_SWITCH_ERROR,
    SAVE_BEFORE_TEST,
    SSO_NOT_CONFIGURED,
    validate
} from './utils';

const SamlSettingsComp = ({ setIsLoading }: SamlSettingsProps) => {
    const [metadataFormat, setMetadataFormat] = useState(0);
    const [authMethod, setAuthMethod] = useState(AuthMethod.SAML);
    const [metadataURL, setMetadataURL] = useState('');
    const [samlSPDetails, setSamlSPDetails] = useState<SAMLSPDetails | {}>({});
    const [oidcSPDetails, setOidcSPDetails] = useState({} as OIDCSPDetails);
    const [idpDetails, setIDPDetails] = useState<IDPDetails | {}>({});
    const [disableSaveButton, setDisableSaveButton] = useState({
        0: true,
        1: true
    });
    const [filename, setFileName] = useState('');
    const [oidcFieldsData, setOidcFieldsData] = useState(initialOIDCFieldsData);
    const [oidcAPIConfiguration, setOidcAPIConfiguration] = useState(
        initialOIDCFieldsData
    );
    const [errors, setErrors] = useState<Record<string, string>>({});
    const currentUser = useSelector(selectUser);
    const theme = useTheme();
    const isSamlSelected = authMethod === AuthMethod.SAML;
    const isEverythingSameForOidc = isEqual(
        oidcFieldsData,
        oidcAPIConfiguration
    );
    const isSaveDisabled = isSamlSelected
        ? disableSaveButton[metadataFormat as keyof typeof disableSaveButton]
        : isEverythingSameForOidc ||
          Object.keys(oidcFieldsData).some(key => {
              const value = oidcFieldsData[key as OIDCKEY] ?? '';
              return value.length === 0 || errors?.[key]?.length > 0;
          });
    const hasOnlySamlPrivilege =
        currentUser.resources.length === 1 &&
        currentUser.resources[0].name === Resources.settings &&
        currentUser.resources[0].privilegeNames.length === 1 &&
        currentUser.resources[0].privilegeNames.includes(Privileges.saml);

    const makeAnApiCall = useCallback(async () => {
        try {
            setIsLoading(true, true);
            const {
                data: { ssoType, saml, oidc }
            } = await getSPDetails();

            setSamlSPDetails(saml);
            setOidcSPDetails({
                authorizationEndpoint: oidc.authorizationEndpoint
            });
            if (ssoType === 'SAML') {
                setAuthMethod(AuthMethod.SAML);
            } else if (ssoType === 'OIDC') {
                const oidcConfiguration = {
                    clientId: oidc.configuration.clientId,
                    clientSecret: oidc.configuration.clientSecret,
                    emailScope: oidc.configuration.emailScope,
                    authorizationBaseUri:
                        oidc.configuration.authorizationBaseUri,
                    tokenUri: oidc.configuration.tokenUri,
                    userInfoUri: oidc.configuration.userInfoUri,
                    jwkSetUri: oidc.configuration.jwkSetUri
                };
                setOidcFieldsData(oidcConfiguration);
                setOidcAPIConfiguration(oidcConfiguration);
                setAuthMethod(AuthMethod.OIDC);
            }
        } catch (err: any) {
            displayErrorMessage(err);
        } finally {
            setIsLoading(false);
        }
    }, [setIsLoading]);

    useEffect(() => {
        makeAnApiCall();
    }, [makeAnApiCall]);

    const handleCapture = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = async (evt: any) => {
            setIDPDetails(parseXMLData(evt.target.result));
            setFileName(file.name);
            setDisableSaveButton({
                ...disableSaveButton,
                [metadataFormat]: false
            });
        };
    };

    const handleSamlSave = async () => {
        let finalIdpDetails: IDPDetails;
        if (metadataFormat) {
            try {
                const response = await getURLDetails(metadataURL);
                finalIdpDetails = parseXMLData(response.data);
            } catch (err: any) {
                if (err.response) {
                    showToast(METADATA_URL_ERROR, TOAST_TYPE.ERROR, TOAST_ID);
                } else {
                    displayErrorMessage(err);
                }
                return;
            }
        } else {
            finalIdpDetails = idpDetails as IDPDetails;
        }
        const emptyCheck = !Object.values(finalIdpDetails).every(
            element => !!element
        );
        if (emptyCheck) {
            showToast(METADATA_ERROR, TOAST_TYPE.ERROR, TOAST_ID);
            return;
        }
        setIsLoading(true);
        postIDPDetails(finalIdpDetails!)
            .then(() => {
                showToast(CONFIGURATION_SAVED, TOAST_TYPE.SUCCESS, TOAST_ID);
                // Reload page first time only when SAML is available in the privileges
                if (hasOnlySamlPrivilege) {
                    window.location.reload();
                }
                setOidcFieldsData(initialOIDCFieldsData);
            })
            .catch((err: any) => {
                displayErrorMessage(err);
            })
            .finally(() => {
                if (metadataFormat) {
                    setMetadataURL('');
                } else {
                    setFileName('');
                }
                setDisableSaveButton({
                    ...disableSaveButton,
                    [metadataFormat]: true
                });
                setIsLoading(false);
            });
    };

    const handleOidcSave = async () => {
        try {
            setIsLoading(true);
            await postOIDCDetails(oidcFieldsData);
            showToast(CONFIGURATION_SAVED, TOAST_TYPE.SUCCESS, TOAST_ID);
            // Reload page first time only when SAML is available in the privileges
            if (hasOnlySamlPrivilege) {
                window.location.reload();
            }
            setOidcAPIConfiguration(oidcFieldsData);
        } catch (error: any) {
            displayErrorMessage(error);
        } finally {
            setIsLoading(false);
        }
    };

    const validateOidcFields = () => {
        const tempErrors = {} as Record<string, string>;
        Object.keys(oidcFieldsData).forEach(key => {
            const value = oidcFieldsData[key as OIDCKEY];
            tempErrors[key] = validate(key, value);
        });

        setErrors(tempErrors);
        return Object.values(tempErrors).every(error => error.length === 0);
    };

    const testConnection = async () => {
        try {
            const { username } = currentUser;
            const response = await getSSOId(
                { username },
                { isTestConnection: 'true' }
            );
            if (response.data.value) {
                const value: string = response.data.value ?? '';
                if (isSamlSelected && value.includes('oauth2')) {
                    showToast(SAML_SWITCH_ERROR, TOAST_TYPE.ERROR, TOAST_ID);
                    return;
                }
                handleSSOPopup(value);
            } else {
                showToast(SSO_NOT_CONFIGURED, TOAST_TYPE.ERROR, TOAST_ID);
            }
        } catch (error: any) {
            displayErrorMessage(error);
        }
    };

    const handleOidcInputChange = (key: string, value: string) => {
        setOidcFieldsData(prev => ({
            ...prev,
            [key]: value
        }));
        setErrors(prev => ({
            ...prev,
            [key]: validate(key, value)
        }));
    };

    const samlSettings = () => (
        <>
            <div className="sp-details">
                <div className="title-wrapper">
                    <div
                        className="title"
                        style={{ fontFamily: 'Proxima Nova Bld' }}
                    >
                        {SAML_SP_DETAILS}
                    </div>
                </div>
                <div className="description">{SAML_SP_DESCRIPTION}</div>
                {SAML_SP_FIELDS.map((key, index) => (
                    <div
                        key={index}
                        className="control code-block theme-default horizontal"
                    >
                        <span className="title">
                            {
                                SAML_SP_MAPPING[
                                    key as keyof typeof SAML_SP_MAPPING
                                ]
                            }
                        </span>
                        <TextToClipBoard
                            text={
                                samlSPDetails[key as keyof typeof samlSPDetails]
                            }
                        />
                    </div>
                ))}
            </div>
            <div className="idp-details">
                <div className="title-wrapper">
                    <div
                        className="title"
                        style={{ fontFamily: 'Proxima Nova Bld' }}
                    >
                        {SAML_IDP_DETAILS}
                    </div>
                </div>
                <div className="description">{SAML_IDP_DESCRIPTION}</div>
                <div className="metadata-select-wrapper">
                    <span className="metadata-select-text">
                        {CHOOSE_METADATA_FORMAT}{' '}
                    </span>
                    <Select
                        MenuProps={{
                            sx: { zIndex: 10002 }
                        }}
                        onChange={e =>
                            setMetadataFormat(e.target.value as number)
                        }
                        value={metadataFormat}
                    >
                        {metadataDropDownValues.map((element, index) => (
                            <MenuItem
                                key={index}
                                style={{
                                    color:
                                        theme.palette.mode === 'dark'
                                            ? 'white'
                                            : ''
                                }}
                                value={index}
                            >
                                {element}
                            </MenuItem>
                        ))}
                    </Select>
                </div>

                {metadataFormat === 0 ? (
                    <div className="file-wrapper" style={{ marginTop: '5px' }}>
                        <div className="element field">
                            <span className="text">{METADATA_FILE}</span>
                        </div>
                        <span className="file-name">{filename}</span>
                        <label
                            className="control file-input"
                            htmlFor="file-upload"
                        >
                            <div className="input">
                                <div>
                                    <input hidden type="file" />
                                    <Button
                                        className="control svg-symbol-button transition theme-5"
                                        color="primary"
                                        component="span"
                                        startIcon={<Upload />}
                                        variant="text"
                                    >
                                        {UPLOAD_FILE}
                                    </Button>
                                </div>
                            </div>
                        </label>
                        <StyledInput
                            accept=".xml"
                            id="file-upload"
                            onChange={handleCapture}
                            type="file"
                        />
                    </div>
                ) : (
                    <div className="url-wrapper">
                        <span className="metadata-url-text">
                            {METADATA_URL}
                        </span>
                        <TextBox
                            autoComplete="off"
                            className="url"
                            fullWidth
                            hiddenLabel
                            InputProps={{
                                endAdornment: metadataURL && (
                                    <InputAdornment
                                        onClick={() => {
                                            setMetadataURL('');
                                            setDisableSaveButton({
                                                ...disableSaveButton,
                                                [metadataFormat]: true
                                            });
                                        }}
                                        position="end"
                                        variant="outlined"
                                    >
                                        <IoIosClose />
                                    </InputAdornment>
                                ),
                                style: {
                                    backgroundColor:
                                        theme.palette.mode === 'dark'
                                            ? '#2B2732'
                                            : '#ffffff'
                                }
                            }}
                            onChange={e => {
                                setMetadataURL(e.target.value);
                                setDisableSaveButton({
                                    ...disableSaveButton,
                                    [metadataFormat]:
                                        e.target.value.length === 0
                                });
                            }}
                            size="small"
                            value={metadataURL as string}
                            variant="outlined"
                        />
                    </div>
                )}
            </div>
        </>
    );

    const isTestConnectionDisabled = () => {
        if (isSamlSelected) return false;
        if (
            !isEqual(initialOIDCFieldsData, oidcAPIConfiguration) &&
            isEverythingSameForOidc
        )
            return false;

        return Object.keys(oidcFieldsData).some(key => {
            const value = oidcFieldsData[key as OIDCKEY] ?? '';
            return value.length === 0 || errors?.[key]?.length > 0;
        });
    };

    const handleSave = () => {
        if (isSamlSelected) {
            handleSamlSave();
        } else if (validateOidcFields()) {
            handleOidcSave();
        }
    };

    const handleTestConnection = () => {
        if (isSamlSelected) {
            if (!isSaveDisabled) {
                showToast(SAVE_BEFORE_TEST, TOAST_TYPE.INFO, TOAST_ID);
            } else {
                testConnection();
            }
            return;
        }

        const isValid = validateOidcFields();
        if (!isValid) return;

        const isAnythingChangedForOidc = !isEqual(
            oidcFieldsData,
            oidcAPIConfiguration
        );

        if (isAnythingChangedForOidc) {
            showToast(SAVE_BEFORE_TEST, TOAST_TYPE.INFO, TOAST_ID);
            return;
        }

        testConnection();
    };

    return (
        <>
            <div style={{ marginBottom: '4px' }}>
                <Header title={LOGIN_MANAGEMENT} />
            </div>
            <div
                className="saml"
                style={{
                    color: theme.palette.mode === 'dark' ? 'white' : '#121212',
                    marginTop: '-30px'
                }}
            >
                <ChooseMethodBlock>
                    <div style={{ marginBottom: '4px' }}>
                        {SSO_LOGIN_METHOD}
                    </div>
                    <Select
                        MenuProps={{
                            sx: { zIndex: 10002 }
                        }}
                        onChange={e =>
                            setAuthMethod(e.target.value as AuthMethod)
                        }
                        value={authMethod}
                    >
                        {authMethodDropDownValues.map(element => (
                            <MenuItem
                                key={element}
                                style={{
                                    color:
                                        theme.palette.mode === 'dark'
                                            ? 'white'
                                            : ''
                                }}
                                value={element}
                            >
                                {element}
                            </MenuItem>
                        ))}
                    </Select>
                </ChooseMethodBlock>
                <div className="saml-settings">
                    {isSamlSelected ? (
                        samlSettings()
                    ) : (
                        <OIDCSettings
                            errors={errors}
                            handleChange={handleOidcInputChange}
                            oidcFieldsData={oidcFieldsData}
                            oidcSPDetails={oidcSPDetails}
                        />
                    )}
                    <StyledActionsBox>
                        {currentUser.isAmmUser ? null : (
                            <StyledButton
                                disabled={isTestConnectionDisabled()}
                                onClick={handleTestConnection}
                                variant="outlined"
                            >
                                Test Connection
                            </StyledButton>
                        )}
                        <Button
                            disabled={isSaveDisabled}
                            onClick={handleSave}
                            variant="contained"
                        >
                            {SAVE}
                        </Button>
                    </StyledActionsBox>
                </div>
            </div>
        </>
    );
};

export const SamlSettings = IsLoadingHOC(SamlSettingsComp);
