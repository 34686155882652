import React, { FC } from 'react';

import { OIDC_SP_MAPPING } from 'src/constants/APIResponse';
import {
    OIDC_SP_DETAILS,
    OIDC_SP_DESCRIPTION,
    OIDC_IDP_DETAILS,
    OIDC_IDP_DESCRIPTION
} from 'src/constants/LabelText';
import { TextToClipBoard } from 'src/pages/components/TextToClipBoard/TextToClipBoard';

import OIDCInput from './OIDCInput';
import { OIDCFieldsContainer } from './SamlSettings.style';
import {
    OIDCConfiguration,
    OIDCKEY,
    OIDCSPDetails
} from './SamlSettings.types';
import { oidcInputs, OIDC_SP_FIELDS } from './utils';

type Props = {
    oidcSPDetails: OIDCSPDetails;
    oidcFieldsData: OIDCConfiguration;
    handleChange: (key: string, value: string) => void;
    errors: Record<string, string>;
};

const OIDCSettings: FC<Props> = ({
    oidcSPDetails,
    oidcFieldsData,
    errors,
    handleChange
}) => (
    <>
        <div className="sp-details">
            <div className="title-wrapper">
                <div
                    className="title"
                    style={{ fontFamily: 'Proxima Nova Bld' }}
                >
                    {OIDC_SP_DETAILS}
                </div>
            </div>
            <div className="description">{OIDC_SP_DESCRIPTION}</div>
            {OIDC_SP_FIELDS.map(key => (
                <div
                    key={`clipboard_${key}`}
                    className="control code-block theme-default horizontal"
                >
                    <span className="title">{OIDC_SP_MAPPING[key]}</span>
                    <TextToClipBoard text={oidcSPDetails?.[key]} />
                </div>
            ))}
        </div>
        <div className="idp-details">
            <div className="title-wrapper">
                <div
                    className="title"
                    style={{ fontFamily: 'Proxima Nova Bld' }}
                >
                    {OIDC_IDP_DETAILS}
                </div>
            </div>
            <div className="description">{OIDC_IDP_DESCRIPTION}</div>
            <OIDCFieldsContainer>
                {oidcInputs.map(input => (
                    <OIDCInput
                        key={input.key}
                        error={errors?.[input.key] ?? ''}
                        handleChange={value => handleChange(input.key, value)}
                        label={input.label}
                        placeholder={input.placeholder}
                        value={oidcFieldsData[input.key as OIDCKEY] ?? ''}
                    />
                ))}
            </OIDCFieldsContainer>
        </div>
    </>
);

export default OIDCSettings;
