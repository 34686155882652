import { Button, constants } from '@armis/armis-ui-library';
import { Box, styled } from '@mui/material';

export const StyledInput = styled('input')`
    display: none;
`;

export const StyledActionsBox = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
`;

export const ChooseMethodBlock = styled('div')`
    font-family: ${constants.PROXIMANOVA_W08_SEMIBOLD};
    font-size: 16px;
    line-height: 20px;
    padding-left: 33px;
    padding-top: 33px;
`;

export const OIDCFieldsContainer = styled('div')`
    display: grid;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 20px;
    grid-template-columns: 210px 1fr;
    grid-template-rows: repeat(6, 40px);
    gap: 25px 0px;

    & input {
        font-size: 13px;
        line-height: 18px;
        padding: 0;
    }

    && .MuiFormHelperText-root {
        margin-top: 0px;
    }
`;

export const StyledButton = styled(Button)`
    padding-left: 15px;
`;
